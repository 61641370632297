@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .truncated {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .break-word {
    word-wrap: break-word;
    word-break: break-word;
  }
}