@layer mantine,
mantine-datatable;

@font-face {
  font-family: 'Startup-Light';
  src: url('../assets/fonts/Startup-Light.otf') format('opentype');
}

@font-face {
  font-family: 'Startup-Regular';
  src: url('../assets/fonts/Startup-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Startup-Medium';
  src: url('../assets/fonts/Startup-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Startup-SemiBold';
  src: url('../assets/fonts/Startup-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'Startup-Bold';
  src: url('../assets/fonts/Startup-Bold.otf') format('opentype');
}

body {
  margin: 0;
  font-family: "Startup-Regular", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 14.768px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.font-startup {
  font-family: 'Startup-Regular';
}

.font-startup-light {
  font-family: 'Startup-Light';
}

.font-startup-medium {
  font-family: 'Startup-Medium';
}

.font-startup-semibold {
  font-family: 'Startup-SemiBold';
}

.font-startup-bold {
  font-family: 'Startup-Bold';
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit;
  /* -webkit-box-shadow: 0 0 0px 1000px #f2f2f2 inset; */
  background-color: #f2f2f2;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #f2f2f2 inset;
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: inherit !important;
}

.menu-close-padding-custom .p-sidebar-header {
  padding-bottom: 0.67rem;
}

.c-pointer {
  cursor: pointer;
}

.p-button:focus {
  box-shadow: none;
}

.hover\:bg-white:hover {
  border-color: transparent !important;
  background-color: rgb(221 221 224 / var(--tw-bg-opacity)) !important;
  color: inherit !important;
}

.mantine-Pagination-control.mantine-active[data-active] {
  background-color: var(--pagination-active-bg) !important;
}

.mantine-Checkbox-input {
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  background-color: var(--_checkbox-bg);
  border: calc(0.0625rem*var(--mantine-scale)) solid var(--_checkbox-bd-color);
  border-radius: 4px;
  cursor: var(--_checkbox-cursor, var(--mantine-cursor-type));
  display: block;
  height: var(--checkbox-size);
  margin: 0;
  padding: 0;
  transition: border-color .1s ease, background-color .1s ease;
  width: var(--checkbox-size);
}

.mantine-Checkbox-input:focus {
  --tw-ring-offset-width: 0;
  --tw-ring-offset-shadow: 0;
  --tw-ring-shadow: 0;
  box-shadow: none;
  outline: none;
}

.mantine-Checkbox-input[type='checkbox']:checked {
  background-color: var(--_checkbox-bg);
  background-image: none;
}

.mantine-NumberInput-input {
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  background-color: var(--_input-bg);
  border: calc(0.0625rem*var(--mantine-scale)) solid var(--_input-bd);
  border-radius: var(--_input-radius);
  color: var(--_input-color);
  cursor: var(--_input-cursor);
  display: block;
  font-family: var(--_input-font-family, var(--mantine-font-family));
  /* font-size: var(--_input-fz,var(--input-fz,var(--mantine-font-size-sm))); */
  /* height: var(--_input-size); */
  /* line-height: var(--_input-line-height); */
  min-height: var(--_input-height);
  overflow: var(--_input-overflow);
  /* padding: var(--input-padding-y,0) var(--_input-padding-right) var(--input-padding-y,0) var(--_input-padding-left); */
  resize: none;
  text-align: var(--_input-text-align);
  transition: border-color .1s ease;
  width: 100%;
}

.mantine-DatePickerInput-input {
  background-color: var(--_input-bg);
}

.mantine-datatable-header-selector-cell,
.mantine-datatable-row-selector-cell {
  background-color: #fff;
}

@media screen and (max-width: 767px) {}